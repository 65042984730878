import { combineReducers } from "redux"
import InterventionDetails from "../unilever/reducer"
import tableSlice from "./tableSlice"
import interventionSlice from "./interventionSlice"
import optionsSlice from "./optionsSlice"
import currencySlice from "./currencySlice"

/**
 * @fileoverview This file combines all the individual reducers into a single root reducer for the Redux store.
 * @author Sushma, Harshini, Sravani
 */
const rootReducer = combineReducers({
  // public
  // simulationSlice,
  tableSlice,
  currencySlice,
  interventionSlice,
  options: optionsSlice,
  InterventionDetails
})

export default rootReducer
