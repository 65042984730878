import { PublicClientApplication } from "@azure/msal-browser"
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react"
import { ConfigProvider } from "antd"
import "antd/dist/reset.css"
import { Provider } from "react-redux"
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom"

import { msalConfig } from "../src/sso/authConfig"
import "./App.css"
import AppRoutes, { authProtectedRoutes, publicRoutes } from "./routes/index"
import { store } from "./redux/config/store"
import theme from "./styles/theme"
import MyLayout from "components/common/layout/sideBar"
import React, { useEffect, useState } from "react"
import { PowerBIProvider } from "components/waste_overview/powerBI/powerbiContext"
import ErrorFallback from "components/common/error"
import { ErrorBoundary } from "react-error-boundary"
import { getHeaders } from "services/utilService"

const msalInstance = new PublicClientApplication(msalConfig)

function App() {

  return(
    <Provider store={store}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ConfigProvider theme={theme}>
        <BrowserRouter>
          <MsalProvider instance={msalInstance}> 
            <AuthenticatedTemplate> 
              <PowerBIProvider>
                <MyLayout >
                  <Routes authProtectedRoutes>
                    {/* Mapping over authProtectedRoutes */}
                    {authProtectedRoutes &&
                      authProtectedRoutes.map((route, index) => (
                        <Route
                          key={index}
                          path={route.path}
                          element={route.element}
                        />
                      ))}
                  </Routes>
                </MyLayout>{" "}
              </PowerBIProvider>
             </AuthenticatedTemplate> 

            <UnauthenticatedTemplate> 
              <Routes>
                {publicRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Routes>
            </UnauthenticatedTemplate>
          </MsalProvider>
        </BrowserRouter>
      </ConfigProvider>
      </ErrorBoundary>
    </Provider>
  )
}
export default App
