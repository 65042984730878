/**
 * @fileoverview This file sets up the Redux store for state management in the application.
 * It imports the currency reducer and configures the store with it.
 * @author Sravani
 */
// src/store/index.js
import { configureStore } from "@reduxjs/toolkit"
import currencyReducer from "../reducer/currencySlice";

export const store = configureStore({
    reducer: {
        currency: currencyReducer,
    },
})
