import { AuthenticationScheme, LogLevel } from "@azure/msal-browser"

/**
 * Configuration for MSAL (Microsoft Authentication Library).
 * @constant
 * @type {object}
 * @author Sushma
 */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_SSO_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri:
      process.env.REACT_APP_SSO_REDIRECT_URI,
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri:
      process.env.REACT_APP_SSO_LOGOUT_REDIRECT_URI, // Make sure this is a full URI
  },

  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            // console.info(message)
            return
          case LogLevel.Verbose:
            // console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
        }
      },
    },
  },
}

/**
 * Configuration for protected resources.
 * @constant
 * @type {object}
 * @author Sushma
 */
export const protectedResources = {
  apiTodoList: {
    endpoint: process.env.REACT_APP_BASE_URL,
    scopes: ["User.Read"],
  },
  powerBiApi: {
    endpoint: process.env.REACT_APP_POWERBI_URL, // Base URL for your Power BI API
    scopes: [process.env.REACT_APP_POWERBI_SCOPES],
  },
}

/**
 * Login request configuration.
 * @constant
 * @type {object}
 * @author Sushma
 */
export const loginRequest = {
  scopes: [...protectedResources.apiTodoList.scopes],
  authenticationScheme: AuthenticationScheme.POP,
  resourceRequestMethod: "GET",
  resourceRequestUri: protectedResources.apiTodoList.endpoint,
}

/**
 * Power BI login request configuration.
 * @constant
 * @type {object}
 * @author Sushma
 */
export const powerBiLoginRequest = {
  scopes: [process.env.REACT_APP_POWERBI_SCOPES],
  authenticationScheme: AuthenticationScheme.POP,
  resourceRequestMethod: "GET",
  resourceRequestUri: process.env.REACT_APP_POWERBI_URL,
}

/**
 * Configuration for Microsoft Graph API services.
 * @constant
 * @type {object}
 * @author Sushma
 */
// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
}
