/**
 * @fileoverview This file contains the layout component for the application, including a sidebar and header.
 * @author Sravani
 */
import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { Avatar, Layout, Menu, Select } from "antd"
import "./sideBar.css"
import Register from "../../../assets/registered intervention white.svg"
import WasteDrilldown from "../../../assets/waste drill down white.svg"
import Logo from "../../../assets/Logo-white-transparent-RGB 2.png"
import { useMsal } from "@azure/msal-react"
import uparrow from "../../../assets/upArrow.svg"
import downarrow from "../../../assets/down Arrow.svg"
import { useDispatch, useSelector } from "react-redux"
import {
  PoweroffOutlined,
  PieChartFilled,
  HomeFilled,
  GlobalOutlined,
} from "@ant-design/icons"
import { setReduxCurrency } from "../../../redux/reducer/currencySlice"
import { usePowerBI } from "components/waste_overview/powerBI/powerbiContext"
import { persistor } from "../../../redux/config/store"
import { trigerAccountCreation } from "sso/msalInstance"
import { getHeaders } from "services/utilService"

const { Header, Sider } = Layout
const { Content } = Layout

const currencyOptions = [
  { value: "Dollar", label: "Dollar" },
  { value: "Euro", label: "Euro" },
]

/**
 * @class MyLayout
 * @description This component renders the main layout with a sidebar, header, and content area.
 */
const MyLayout = props => {
  const { Option } = Select
  const { instance, accounts } = useMsal()
  const [selectedIcon, setSelectedIcon] = useState(null)
  const [isActive, setActive] = useState(false)
  const [activeItem, setActiveItem] = useState("")
  const [imageUrl, SetImageUrl] = useState(null)
  const fullName = accounts[0]?.name || "User"
  const firstName = fullName.split(" ")[1]
  const lastName = fullName.split(" ")[0]
  const [isHovered, setIsHovered] = useState(false)
  const [powerBILink, setPowerBILink] = useState([])
  const { setPowerBILinkItem } = usePowerBI() // Access the setter function from context
  const dispatch = useDispatch()
  const currency = useSelector(state => state.currencySlice.currency)
  trigerAccountCreation()
  useEffect(() => {
    fetchPowerBILinks()
  }, [])

const fetchPowerBILinks = async () => {
  try {
  
    // Perform the API call with the Authorization header
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/reports/getReports`,
      {
        method: "GET", // Or "POST" depending on your API
         headers: await getHeaders(),
      }
    );
    // Check if the response is successful
    if (!response.ok) {
      throw new Error("Failed to fetch Power BI links");
    }

    const data = await response.json();

    // Map the data into the desired format
    const updatedPowerBILinks = data.data.map((item, index) => ({
      key: index + 1,
      title: item.Title,
      icon: "WasteDrilldown",  // Replace with actual icon if needed
      name: item.Name,
      path: item.Path,
      reportType: item.ReportType,
    }));

    // Update state with the fetched data
    setPowerBILink(updatedPowerBILinks);
    // setLoading(false); // Update loading state
  } catch (error) {
    console.error("Error fetching Power BI links:", error);
    // setLoading(false); // Ensure loading state is updated even in case of error
  }
};

  // Fetch the Power BI links dynamically from an API

  /**
   * @function getInitials
   * @description Extracts the initials from a full name.
   * @param {string} name - The full name of the user.
   * @returns {string} The initials of the user.
   */
  const getInitials = name => {
    const nameParts = name.split(" ")
    const firstNameInitial = nameParts[0]?.charAt(0).toUpperCase() || ""
    const lastNameInitial =
      nameParts.length > 1
        ? nameParts[1]?.charAt(0).toUpperCase()
        : firstNameInitial
    return `${firstNameInitial}${lastNameInitial}`
  }
  const initials = getInitials(fullName)

  /**
   * @function handleCurrencyChange
   * @description Handles the change of currency selection.
   * @param {string} name - The name of the currency.
   * @param {string} value - The selected currency value.
   */
  const handleCurrencyChange = (name, value) => {
    setActive(false)
    // setCurrency(value)
    dispatch(setReduxCurrency(value))
  }
  const [dropdownOpen, setDropdownOpen] = useState(false)
  /**
   * @function handleChange
   * @description Handles the selection change in the dropdown.
   * @param {any} value - The selected dropdown value.
   */
  const handleChange = value => {
    onChange && onChange(name, value)
    setDropdownOpen(false)
  }
  /**
   * @function handleDropdownVisibleChange
   * @description Controls the visibility of the dropdown.
   * @param {boolean} open - The state indicating if the dropdown is open.
   */
  const handleDropdownVisibleChange = open => {
    setDropdownOpen(open)
  }
  /**
   * Sets the page title and subtitle based on the current route.
   * @constant {string} title - The main title of the page.
   * @constant {string} subtitle - The subtitle of the page based on route.
   */
  const location = useLocation()

  let title = "Cost to Serve - Deep Dive Tool"
  let subtitle = ""

  switch (location.pathname) {
    case "/service":
      subtitle = "landing"
      break
    case "/country":
      subtitle = "Country KPI View"
      break
    case "/registered_Intervention":
      subtitle = "Registered Intervention Center"
      break
    case "/ai_suggestions":
      subtitle = "AI-Suggested Intervention Center"
      break
    case "/waste_overview":
      subtitle = "WASTE Overview"
      break
    case "/waste_deepdive":
      subtitle = "WASTE Deep Dive"
      break
    default:
      const foundLink = powerBILink?.find(pb => pb.path === location.pathname)
      if (foundLink) {
        subtitle = foundLink.title
      } else {
        subtitle = "Overview"
      }
      break
  }
  /**
   * @function handleIconClick
   * @description Handles the icon selection event.
   * @param {any} icon - The selected icon.
   */
  const handleIconClick = icon => {
    setSelectedIcon(icon)
  }

  /**
   * @function handleLogout
   * @description Logs the user out of the application.
   * @param {string} logoutType - The type of logout to perform (popup or redirect).
   */
  const handleLogout = logoutType => {
    dispatch(setReduxCurrency({ value: "Dollar", label: "Dollar" }))
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: `${process.env.REACT_APP_SSO_REDIRECT_URI}`,
        mainWindowRedirectUri: "",
      })
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: `${process.env.REACT_APP_SSO_REDIRECT_URI}`,
      })
    }
    persistor.purge()
    dispatch({
      type: "RESET_TO_INITIAL",
      payload: "",
    })
      //  localStorage.removeItem("authUser");

    // Remove from localStorage
    localStorage.removeItem(`CTS - 2.0 ${process.env.REACT_APP_ENV}`)
  }

  /**
   * @function isActiveTab
   * @description Checks if the current path is active.
   * @param {string} path - The path to check against.
   * @returns {boolean} True if the path is active, false otherwise.
   */
  const isActiveTab = path => {
    return location.pathname.startsWith(path)
  }

  return (
    <Layout className="layout-container">
      {location.pathname !== "/service" && (
        <Sider width={70} theme="light">
          <img
            // src={Group}
            src={Logo}
            alt="unileverlogo"
            width="100%"
            height="70px"
            className="sider-logo"
          />
          <Menu
            className="menu-bar"
            mode="vertical"
            defaultSelectedKeys={["1"]}
            selectedKeys={[location.pathname]}
          >
            <Menu.Item
              key="1"
              className={`tabs ${isActiveTab("/service") ? "active-tab" : ""}`}
              onClick={() => handleIconClick("service")}
            >
              {" "}
              <Link to="/service">
                <div>
                  {/* <img src={countryView} alt="Service" /> */}
                  {/* <AppstoreFilled/> */}
                  <HomeFilled
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      color: "white",
                      paddingLeft: "10px",
                    }}
                  />
                  <p>Landing</p>
                </div>
              </Link>
            </Menu.Item>
            {/* <Menu.Item
              key="1"
              className={`tabs ${isActiveTab("/country") ? "active-tab" : ""}`}
              onClick={() => handleIconClick("country")}
            >
              {" "}
              <Link to="/country">
                <div>
                
                  <GlobalOutlined
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      color: "white",
                      paddingLeft: "10px",
                    }}
                  />
                  <p>Cost to Serve_Global View</p>
                </div>
              </Link>
            </Menu.Item> */}
            <Menu.Item
              key="2"
              className={`tabs ${isActiveTab("/ai_suggestions") ? "active-tab" : ""}`}
              onClick={() => handleIconClick("ai_suggestions")}
            >
              <Link to="/ai_suggestions">
                {/* <img src={AiSuggested} alt="SkU" /> */}
                <PieChartFilled
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    color: "white",
                    paddingLeft: "10px",
                  }}
                />
                <p>AI-Suggested Intervention Center</p>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="3"
              className={`tabs ${isActiveTab("/registered_Intervention") ? "active-tab" : ""}`}
              onClick={() => handleIconClick("registered_Intervention")}
            >
              <Link to="/registered_Intervention">
                <img
                  src={Register}
                  alt="Catanaly"
                  style={{
                    paddingLeft: "10px",
                    width: "30px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                />
                {/* <AppstoreFilled
                style={{ fontSize: "20px", cursor: "pointer", color: "white", paddingLeft: "10px", }}
              /> */}
                <p>Registered Intervention Center</p>
              </Link>
            </Menu.Item>
            {/* <Menu.Item
              key="4"
              className={`tabs ${isActiveTab("/waste_overview") ? "active-tab" : ""}`}
              onClick={() => handleIconClick("waste_overview")}
            >
              {/* <Link to="/waste_overview/heightLoss">
                <img
                  src={WasteOverview}
                  alt="Actioncenter"
                  style={{
                    paddingLeft: "10px",
                    width: "30px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                />
                {/* <AppstoreFilled
                style={{ fontSize: "20px", cursor: "pointer", color: "white", paddingLeft: "10px", }}
              /> */}
            {/* <p>WASTE Overview</p> */}
            {/* </Link> 
            </Menu.Item>  */}
            <>
              {powerBILink.map(pb => (
                <Menu.Item
                  key={pb.key}
                  className={`tabs ${isActiveTab(pb.path) ? "active-tab" : ""}`}
                  onClick={() => {
                    setSelectedIcon(pb.name)
                    setPowerBILinkItem(pb)
                  }}
                >
                  <Link to={pb.path}>
                    {pb.title == "Waste Overview & Deep Dive" ? (
                      <img
                        src={WasteDrilldown} // Or use pb.icon if applicable
                        alt={pb.title}
                        style={{
                          paddingLeft: "10px",
                          width: "30px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <GlobalOutlined
                        style={{
                          fontSize: "20px",
                          cursor: "pointer",
                          color: "white",
                          paddingLeft: "10px",
                        }}
                      />
                    )}
                    <p>{pb.title}</p>
                  </Link>
                </Menu.Item>
              ))}
            </>
            {/* <Menu.Item
            key="5"
            className={`tabs ${location.pathname === "/waste_deepdive" ? "active-tab" : ""}`}
            onClick={() => handleIconClick("waste_deepdive")}
          >
            <Link to="/waste_deepdive">
              <img
                src={WasteDrilldown}
                alt="Actioncenter"
                style={{
                  paddingLeft: "10px",
                  width: "30px",
                  height: "20px",
                  cursor: "pointer",
                }}
              />
              <p>WASTE Deepdive</p>
            </Link>
          </Menu.Item> */}
          </Menu>
        </Sider>
      )}

      <Layout>
        {location.pathname !== "/service" &&
          location.pathname !== "/Country_Benchmarking" &&
          location.pathname !== "/Waste_Deep_Dive" && (
            <div className="header-container">
              <div className="profile-header">
                <div className="left-side">
                  <div className="title">{title}</div>
                  <div className="title-sub-title">{subtitle}</div>
                </div>
                <div className="right-side">
                  {/* {!isActive && (
                <p
                  style={{
                    color: "#292929",
                    marginTop: "22px",
                    fontSize: "14px",
                    fontFamily: "Unilever Shilling",
                  }}
                  onClick={handleClick}
                >
                  Select Currency Type($)
                </p> */}
                  {/* )} */}
                  {/* {isActive && ( */}
                  {location.pathname !== "/ai_suggestions/options" && (
                    <div className="filter-section">
                      <Select
                        className="filter-dropdown"
                        placeholder="Select here"
                        onChange={handleCurrencyChange}
                        value={currency}
                        suffixIcon={
                          dropdownOpen ? (
                            <img
                              src={uparrow}
                              alt="up Arrow"
                              style={{ width: "20px", height: "20px" }}
                            />
                          ) : (
                            <img
                              src={downarrow}
                              alt="down arrow"
                              style={{ width: "20px", height: "20px" }}
                            />
                          )
                        }
                      >
                        {currencyOptions.map(option => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                  {/* )} */}
                  <div>
                    <Avatar
                      width="32px"
                      height="32px"
                      className="profile-picture"
                    >
                      {initials}
                    </Avatar>
                  </div>
                  <div className="profile-name">
                    {firstName} <br />
                    {/* <span style={{ fontSize: "12px" }}> {lastName}</span>
                     */}
                  </div>
                  <PoweroffOutlined
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      color: "#292929",
                      marginTop: "13px",
                    }}
                    onClick={() => handleLogout("popup")}
                  />
                </div>
              </div>
            </div>
          )}

        <Content
          className="before-login-main-content"
          style={{
            // padding: 10,
            overflowX: "auto",
            scrollbarWidth: isHovered ? "thin" : "none",
            // marginTop: "1rem"
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  )
}

export default MyLayout
