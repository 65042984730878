import {
  SELECTED_INTERVENTION,
  ADD_STIMULATION_TO_CART,
  DELETE_INTERVENTION_TO_CART,
  DELETE_STIMULATION_TO_CART,
  RESET_TO_CART,
  SELECT_DENSITY_LOSS,
  SELECTED_FILTERAI,
  SELECTED_FILTERRI,
  SELECTED_FILTERDENSITY,
  SELECTED_FILTERINTERNAL,
  RESET_TO_INITIAL,
  SELECTED_FILTERHEIGHT,
  RESET_FILTERHEIGHT,
  SELECTED_FILTEROVERVIEW
} from "./actionTypes"

const INIT_STATE = {
  intervention: [],
  stimulation: [],
  filterListData: {
    country: "US",
    wasteLevel1: "",
    wasteLevel2: "",
    area: "",
    businessGroup: "BEAUTY & WELLBEING",
    interventionBucket: "",
    simulation: "",
  },
  filterListRI: {
    country: "US",
    wasteLevel1: "",
    wasteLevel2: "",
    area: "",
    businessGroup: "",
    interventionBucket: "",
    simulation: "",
    registeredBy: "",
  },
  overviewFilters: {
    country: "US",
    businessGroup: "",
    TargetDensity: "",
    riskFactor2Y: 25,
    riskSavingfactor: 25,
    primaryRisk: 75,
    palletRisk:25,
    maxPalletWeight_single: 2205.0,
    maxPalletWeight_double: 2205.0,
  },
  densityFilter: {
    country: "US",
    // businessGroup: state.intervention[0].bg,
    businessGroup: "",
    Category: "",
    format: [],
    PackType: [],
    PackSize: [],
    TargetDensity: "",
    riskFactor2Y: 25,
    riskSavingfactor: 25,
    primaryRisk: 75,
    palletRisk:25,
    maxPalletWeight_single: 2205.0,
    maxPalletWeight_double: 2205.0,
  },
  internalFilter: {
    country: "US",
    businessGroup: "",
    Category: "",
    format: "",
    PackType: "",
    savings: 4000,
  },
  heightFilter: {
    country:"US",
    businessGroup: "",
    category: "",
    brand:"",
    format: "",
    sizePackForm: "",
    specialPack:"",
  },
  densityLoss: [],
  error: {},
}

const InterventionDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SELECTED_INTERVENTION:
      return {
        ...state,
        intervention: [action.payload],
      }
    case SELECTED_FILTERAI:
      return {
        ...state,
        filterListData: action.payload,
      }
    case SELECTED_FILTERRI:
      return {
        ...state,
        filterListRI: action.payload,
      }
    case SELECTED_FILTERDENSITY:
      return {
        ...state,
        densityFilter: action.payload,
      }
    case SELECTED_FILTEROVERVIEW: 
      return {
        ...state,
        overviewFilters: action.payload
      }
    case SELECTED_FILTERINTERNAL:
      return {
        ...state,
        internalFilter: action.payload,
      }
      case SELECTED_FILTERHEIGHT:
      return {
        ...state,
        heightFilter: action.payload,
      }
      case RESET_FILTERHEIGHT:
      return {
        ...state,
        heightFilter:  {
          country:"US",
          businessGroup: "",
          category: "",
          brand:"",
          format: "",
          sizePackForm: "",
          specialPack:"",
        },
      }
    case SELECT_DENSITY_LOSS:
      return {
        ...state,
        densityLoss: [action.payload],
      }
    case ADD_STIMULATION_TO_CART:
      return {
        ...state,
        stimulation: [...state.stimulation, action.payload],
      }

    case DELETE_INTERVENTION_TO_CART:
      return {
        ...state,
        datacart: action.payload,
      }
    case DELETE_STIMULATION_TO_CART:
      return {
        ...state,
        stimulation: action.payload,
      }

    case RESET_TO_INITIAL:
      return {
        intervention: [],
        stimulation: [],
        filterListData: {
          country: "US",
          wasteLevel1: "",
          wasteLevel2: "",
          area: "",
          businessGroup: "BEAUTY & WELLBEING",
          interventionBucket: "",
          simulation: "",
        },
        filterListRI: {
          country: "US",
          wasteLevel1: "",
          wasteLevel2: "",
          area: "",
          businessGroup: "",
          interventionBucket: "",
          simulation: "",
          registeredBy: "",
        },
        densityFilter: {
          country: "US",
          // businessGroup: state.intervention[0].bg,
          businessGroup: "",
          Category: "",
          format: [],
          PackType: [],
          PackSize: [],
          TargetDensity: "",
          riskFactor2Y: 20,
          riskSavingfactor: 20,
          maxPalletWeight_single: 2205.0,
          maxPalletWeight_double: 2205.0,
        },
        overviewFilters: {
          country: "US",
          businessGroup: "",
          TargetDensity: "",
          riskFactor2Y: 25,
          riskSavingfactor: 25,
          primaryRisk: 75,
          palletRisk:25,
          maxPalletWeight_single: 2205.0,
          maxPalletWeight_double: 2205.0,
        },
        internalFilter: {
          country: "US",
          businessGroup: "",
          Category: "",
          format: "",
          PackType: "",
          savings: 4000,
        },
        densityLoss: [],
        error: {},
        heightFilter: {
          country:"US",
          businessGroup: "",
          category: "",
          brand:"",
          format: "",
          sizePackForm: "",
          specialPack:"",
        },
      }
      

    default:
      return state
  }
}

export default InterventionDetails
