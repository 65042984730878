// src/store/slices/currencySlice.js
import { createSlice } from "@reduxjs/toolkit"

/**
 * @fileoverview This file defines the Redux slice for currency and conversion rates.
 * It includes actions for setting the currency and updating conversion rates.
 * @author Sravani
 */
const initialState = {
    currency: {
        key: "Dollar",
        value: "Dollar",
        children: "Dollar"
    },
    conversionRates: {
        Dollar: 1,
        Euro: 0.925926,
    },
}

/**
 * @function
 * @name createSlice
 * @description Creates a Redux slice for currency management, including currency and conversion rates.
 * @returns {object} Contains actions and reducers for managing currency state.
 */
export const currencySlice = createSlice({
    name: "currency",
    initialState,
    reducers: {
        /**
        * @function
        * @name setReduxCurrency
        * @description Updates the currency in the Redux store.
        * @param {object} state - The current Redux state.
        * @param {object} action - The action containing the payload for the new currency.
        */
        setReduxCurrency: (state, action) => {
            state.currency = JSON.parse(JSON.stringify(action.payload))
        },
        /**
        * @function
        * @name setConversionRates
        * @description Updates the conversion rates in the Redux store.
        * @param {object} state - The current Redux state.
        * @param {object} action - The action containing the new conversion rates.
        */
        // If you have dynamic conversion rates from an API, you can add logic here
        setConversionRates: (state, action) => {
            state.conversionRates = action.payload
        },
    },
})

// Exporting actions for use in components
export const { setReduxCurrency, setConversionRates } = currencySlice.actions

/**
 * @function
 * @name selectCurrency
 * @description Selector to get the current currency from the Redux store.
 * @param {object} state - The Redux state.
 * @returns {object} The current currency stored in Redux.
 */
export const selectCurrency = state => state.currency.currency

/**
 * @function
 * @name selectConversionRates
 * @description Selector to get the conversion rates from the Redux store.
 * @param {object} state - The Redux state.
 * @returns {object} The conversion rates stored in Redux.
 */
export const selectConversionRates = state => state.currency.conversionRates

// Exporting the reducer for use in the Redux store configuration
export default currencySlice.reducer
