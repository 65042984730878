// powerBIContext.js
import { createContext, useContext, useState } from 'react';


/**
 * @constant PowerBIContext
 * @description Creates a React context for managing Power BI related state globally in the application.
 * @author Sushma
 */
// Create the context
const PowerBIContext = createContext();

/**
 * @component PowerBIProvider
 * @description Context provider component to wrap around components that need access to Power BI link state.
 * @param {object} props - Component props.
 * @param {React.ReactNode} props.children - The child components that will be wrapped by the provider.
 * @returns {JSX.Element} The provider component to wrap around child components.
 * @author Sushma
 */
// Create a provider component
export const PowerBIProvider = ({ children }) => {
  const [powerBILinkItem, setPowerBILinkItem] = useState(null);

  return (
    <PowerBIContext.Provider value={{ powerBILinkItem, setPowerBILinkItem }}>
      {children}
    </PowerBIContext.Provider>
  );
};

/**
 * @function usePowerBI
 * @description Custom hook to access the Power BI context.
 * @returns {object} The current Power BI context value with link and setter functions.
 * @author Sushma
 */
// Hook to use the context
export const usePowerBI = () => {
  return useContext(PowerBIContext);
};
