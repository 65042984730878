import { PublicClientApplication, EventType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

/**
* Initializes the MSAL instance for authentication.
* @constant msalInstance
* @type {PublicClientApplication}
*/
export const msalInstance = new PublicClientApplication(msalConfig);

/**
* Function to ensure that the MSAL instance is initialized before using it.
*/
const initializeMSAL = async () => {
  try {
    // Ensure the MSAL instance is initialized
    await msalInstance.initialize();
    console.log("MSAL instance initialized");
  } catch (error) {
    console.error("Error initializing MSAL:", error);
  }
};

// Initialize MSAL as soon as the app is loaded
initializeMSAL();

// Now you can call any MSAL API after initialization

/**
* Retrieves all accounts and sets the first account as the active account if available.
* @constant accounts
* @type {Array}
*/
  let accounts=[]
  export const trigerAccountCreation = () => {
  accounts = msalInstance.getAllAccounts();
    // console.log("accounts ", accounts);
    triggerSetActiveAccount()
  }
  const triggerSetActiveAccount = () => {
    if (accounts.length > 0) {
    const activeAccount = accounts[0]; // Set the first available account
    msalInstance.setActiveAccount(activeAccount);
    // Store only the active account info in localStorage for simplicity
    localStorage.setItem("authUser", JSON.stringify(activeAccount));
  }
  }


// Handle token acquisition after initialization
msalInstance.addEventCallback(async (event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);

    // Token request object with the required scopes
    const tokenRequest = {
      scopes: ["user.read", "openid", "profile"],
    };

    try {
      // Check for an existing valid token in cache
      const response = await msalInstance.acquireTokenSilent(tokenRequest);
      const access_token = response.accessToken;
      const id_token = response.idToken;

      // Store the new tokens and user information
      localStorage.setItem("authUser", JSON.stringify(response));

      // console.log("line 63  ",localStorage.getItem("authUser"));
      
      // if (!localStorage.getItem("isFirstLoginDone")) {
      //   console.log("first login");
      //   localStorage.setItem("isFirstLoginDone", "true");
      //   window.location.reload(); // Reload the page
      // }

      return {
        userAccessToken: access_token,
        userIdToken: id_token,
      };
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          const response = await msalInstance.acquireTokenPopup(tokenRequest);
          const access_token = response.accessToken;
          const id_token = response.idToken;

          // Store the new tokens and user information
          localStorage.setItem("authUser", JSON.stringify(response));

          return {
            userAccessToken: access_token,
            userIdToken: id_token,
          };
        } catch (popupError) {
          console.error("Popup token acquisition failed:", popupError);
        }
      } else {
        console.error("Silent token acquisition failed:", error);
      }
    }
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    console.log("Login failed:", JSON.stringify(event)); // More descriptive error logging
  }
});

// Function to get the ID token
export const getIdToken = async () => {
  const account = msalInstance.getActiveAccount();
  const request = {
    scopes: ["user.read"],
    account: account,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(request);
    return response.idToken;
  } catch (error) {
    console.error("Error acquiring token silently:", error);
    if (error instanceof InteractionRequiredAuthError) {
      try {
        const response = await msalInstance.acquireTokenRedirect(request);
        return response.idToken;
      } catch (redirectError) {
        console.error("Error acquiring token interactively:", redirectError);
      }
    }
  }
};
// Function to set a mock expiry time for testing (30 seconds from now)
// function mockTokenExpiry() {
//   const authUser = JSON.parse(localStorage.getItem("authUser"));
//   if (authUser && authUser.idTokenClaims) {
//     const currentTime = Math.floor(Date.now() / 1000);
//     authUser.idTokenClaims.exp = currentTime + 80;
//     localStorage.setItem("authUser", JSON.stringify(authUser)); // Save to localStorage
//   } else {
//     console.log("authUser not found in localStorage to mock expiry.");
//   }
// }
// mockTokenExpiry(); // Only mock in development mode
// Function to check token expiry and handle refresh if needed
async function handleTokenExpiry() {
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  if (authUser && authUser.idTokenClaims) {
    const { exp } = authUser.idTokenClaims;
    const currentTime = Math.floor(Date.now() / 1000);

    if (currentTime >= exp - 20) {
      console.log("Token has expired, refreshing token...");
      
      const tokenRequest = {
        scopes: ["user.read", "openid", "profile"], // Define the scopes based on your needs
        forceRefresh: true,
      };

      try {
        const response = await msalInstance.acquireTokenSilent(tokenRequest);
        // Store the refreshed token
        localStorage.setItem("authUser", JSON.stringify(response));
      } catch (err) {
        console.error("Token refresh failed:", err);
      }
    } else {
      // console.log(`Token will expire in ${exp - currentTime} seconds`);
    }
  } else {
    console.log("authUser not found in localStorage");
  }
}

// Call this function periodically (e.g., every 30 seconds) to check the token status
setInterval(handleTokenExpiry, 10000); // Poll every 30 seconds for token expiry
