import { createSlice } from "@reduxjs/toolkit"

/**
 * @fileoverview This file defines a Redux slice for managing selected options including size of prize, ddwaste, and tableKey.
 * @author Harshini
 */
const initialState = {
  selectedOption: {
    sizeOfPrize: "",
    ddwaste: "",
    tableKey: null,
  },
}

const optionsSlice = createSlice({
  name: "optionsSlice",
  initialState,
  reducers: {
    /**
     * Adds the selected option to the state. Only updates fields provided in the payload.
     * @param {Object} state - The current state of the slice.
     * @param {Object} action - The action payload containing the selected option details.
     */
    addselectedOption: (state, action) => {
      if (action.payload?.sizeOfPrize) {
        state.selectedOption.sizeOfPrize = action.payload?.sizeOfPrize
      }
      if (action.payload?.ddwaste) {
        state.selectedOption.ddwaste = action.payload?.ddwaste
      }
      if (action.payload?.tableKey) {
        state.selectedOption.tableKey = action.payload?.tableKey
      }
    },
    /**
     * Removes the selected option by resetting all fields to their default values.
     * @param {Object} state - The current state of the slice.
     * @param {Object} action - The action payload (not needed here but reserved for future use).
     */
    removeselectedOption: (state, action) => {
      state.selectedOption.sizeOfPrize = ""
      state.selectedOption.ddwaste = ""
      state.selectedOption.tableKey = null
    },
  },
})

export const { addselectedOption, removeselectedOption } = optionsSlice.actions
export default optionsSlice.reducer
